<template>
  <a-modal
    title="新增"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" @submit="handleSubmit">
      <a-form-item label="入库通知单">
        <a-select v-decorator="['receipt_order', { rules: [{ required: true, message: '请选择入库通知单' }]}]" size="small" style="width: 100%">
          <a-select-option v-for="receipt in receiptOrderItems" :key="receipt.id" :value="receipt.id">
            {{ receipt.number }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="计费数量">
        <a-input v-decorator="['measure_value', { rules: [{ required: true, message: '请输入计费数量' }]}]" />
      </a-form-item>
      </a-form>
  </a-modal>
</template>
<script>
import { receiptOrdersOption } from '@/api/option'
import { chargeHandlingInCreate } from '@/api/charging'

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      receiptOrderItems: [],
    }
  },
  methods: {
    show () {
      this.visible = true
      receiptOrdersOption({ page_size: 999999 }).then(data => {
        this.receiptOrderItems = data.results
      })
    },
    handleOk () {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true
          chargeHandlingInCreate(values).then(data => {
            this.$message.success('创建成功')
            this.$emit('ok')
            this.handleCancel()
          }).finally(() => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
</script>